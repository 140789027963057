@import "../../global.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
}
.nav {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 70px;
  background: #42c2ff;
  overflow: hidden;
  z-index: 2;

  .logo {
    margin-left: 50px;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    color: #f1ffe7;
  }

  .hamburger {
    display: none;
  }
  .navLinksDesktop {
    display: flex;
    width: 33%;
    align-items: center;
    justify-content: space-between;
    margin-right: 50px;
    list-style: none;
    font-weight: 200;
    font-size: 1.5rem;
    cursor: pointer;
    a {
      text-decoration: none;
      color: #481620;
    }
    a:hover {
      color: #baff29;
      // border-bottom: 2px solid green;
    }
  }
}

@media (max-width: 990px) {
  .nav {
    .hamburger {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: #42c2ff;
      font-size: 30px;
      color: #f0efec;
      border-color: transparent;
      justify-content: center;
      :hover {
        color: #baff29;
      }
    }
    .logo {
      display: flex;
      position: absolute;
      left: 25px;
      font-weight: 500;
    }

    .navLinksDesktop {
      display: none;
    }
    .navLinksMobile {
      position: fixed;
      display: block;
      top: 65px;
      height: 50vh;
      width: 100%;
      // list-style-type: none;
      background-color: #1a1b41;
      transition: all 1s ease-out;
      justify-items: center;
      justify-content: space-evenly;

      li {
        width: 100%;
        text-align: center;
        transition: all 0.5s ease;
        list-style: none;
        padding: 35px 15px;

        a {
          color: white;
          text-decoration: none;
          font-size: calc(1rem + 1.5vw);
          justify-content: space-evenly;
        }
      }
    }
  }
}
