@import "./../../global.scss";
.landing {
  background-color: white;
  display: flex;
  font-family: "Cairo", sans-serif;
  flex-wrap: wrap;
  .right {
    flex: 50%;
    padding-left: 50px;
    .landingPresentation {
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 2rem;
      gap: 2rem;
    }
    h1 {
      font-size: calc(6rem + 1vw);
      color: #ddbeaa;
      margin: 0;
      line-height: 1.2;
      font-weight: 600;
      padding: 0;
    }
    h2 {
      font-size: calc(3rem + 1vw);
      line-height: 1.2;
      font-weight: 500;
      padding: 0;
      margin: 0;
    }

    .grey {
      color: #469597;
    }

    .SeeMywork {
      left: 35rem;
      padding: 8px 12px;
      border-radius: 5px;
      font-size: calc(1rem+ 0.75vw);
      margin: 0;
      background-color: white;
      color: #1a1b41;
      border-color: #baff29;
      margin-top: 1rem;
      line-height: 1.2;
      &:hover {
        background-color: rgb(186, 255, 41);
        color: #1a1b41;
        border-color: hsl(188, 43%, 18%);
      }
    }
    .contactIcons {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: calc(1rem + 1vw);
      padding-top: 50px;
      gap: 1rem;
      .fa-linkedin-square {
        color: #0a66c2;
      }

      .fa-whatsapp {
        color: #25d366;
      }
    }
  }
  .left {
    flex: 50%;
    display: flex;
    // background-color: azure;
    .myImage {
      height: auto; // border: 2px solid red;
      .me {
        height: auto;
      }
    }
  }
}
@media (max-width: 990px) {
  .landing {
    display: flex;
    height: 64rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .right {
      flex-direction: column;
    }
    .left {
      flex-direction: column;
      .myImage {
        .me {
        }
      }
    }
  }
}
